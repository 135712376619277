@import './colors.scss';

.form-error {
  color: $orange;
  margin: 15px;
  display: none;
  flex-grow: 1;
  align-items: center;

  .inline_error {
    flex-basis: 10%;
    margin-right: 15px;
  }

  .error-message {
    flex-basis: 90%;
  }
}

.form-submit-attempt {
  .form-error {
    display: flex;
  }
  .mat-select:not(.ng-valid),
  textarea.mat-input-element:not(.ng-valid),
  input.mat-input-element:not(.ng-valid) {
    border: 2px solid $darkorange;
  }
}

.mat-select-panel.big-panel {
  max-height: 500px;
}
.mat-select-panel.no-borders {
  .mat-option {
    span.mat-option-text {
      border-bottom: 0;
    }
  }
}

.mat-option.child {
  margin-left: 30px;
}
.mat-option {
  &.no-box {
    border-bottom: 1px solid #AAA;
    margin-top: 3px !important;
    .mat-pseudo-checkbox {
      display: none;
    }
  }
  &.hidden {
    display: none;
  }
}

.mat-select-panel .mat-option:last-of-type {
  margin-left: 2px;
  margin-top: -4px;
  margin-bottom: 3px;
}

.MuiFormLabel-asterisk {
  color: $orange;
  font-weight: 100;
  margin-left: 2px;
}
.mat-select-trigger{
  height: 38px;
  margin-top: 5px;
}
  .mat-select-arrow-wrapper{
    padding: 5px 20px;
    margin-top: 4px;
  }