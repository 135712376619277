/**
* Buttons
*
**/
.button {
  background: $orange;
  border-style: none;
  border-radius: 30px;
  color: #fff;
  cursor: pointer;
  font-size: 30px;
  font-weight: bold;
  position: relative;
  text-transform: uppercase;
  transition: 0.3s ease all;
  width: 200px;
  min-width: 200px;
  height: 48px;
  margin: 0 auto;
  outline: none;

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
}

.button span {
  z-index: 2;
  position: relative;
  font-size: 18px;
  font-weight: 600;
}

.button:before {
  background: linear-gradient(90deg, $orange, $yellow);
  border-radius: 30px;
  content: '';
  width: 100%;
  height: 100%;
  opacity: 1;
  position: absolute;
  top: 0;
  left: 0;
  transition: 0.3s ease all;
  z-index: 1;
}

.button:hover {
  background: $blue;
}

.button:hover:before {
  background: $orange;
  opacity: 0;
}

.button:hover {
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.3);
}

.button:disabled {
  transition: none;
  opacity: 0.5;
}

.button:disabled:hover {
  transition: none;
  background: $orange;
  box-shadow: none;
  cursor: default;
}

.button:disabled:hover:before {
  background: linear-gradient(90deg, $orange, $yellow);
  opacity: 0.5;
}

@media only screen and (max-width: 767px) {
  .button:hover,
  .button:before {
    background: linear-gradient(90deg, $orange, $yellow);
    transition: none;
    box-shadow: none;
  }
}

.button-no-outline {
  width: 150px;
  min-width: 150px;
  height: 40px;
  outline: none;
  padding: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  outline: none;
  background: rgb(235, 235, 235);
  border: 1px solid #fafafa;
  font-weight: 600;
  color: $blue;
  font-size: 14px;
  border-radius: 20px;
  cursor: pointer;
}
.button-no-outline:hover {
  background: rgb(240, 240, 240);
}