@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
@import url("https://cdn.materialdesignicons.com/3.0.39/css/materialdesignicons.min.css");
@import "./backup-font.scss";
@import url("https://use.typekit.net/wuh7ohl.css");
@import "@angular/material/prebuilt-themes/indigo-pink.css";
@import "./colors.scss";
@import "./button.scss";
@import "./forms.scss";
@import "./material.scss";
@import "./parent-view.scss";
@import "./toggle.scss";

@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'leaflet/dist/leaflet.css';

/**
* Common
*
**/
body {
  position: relative;
}

body,
.mat-app-background {
  font-family: "open-sans", sans-serif;
  font-weight: normal;
  color: $primary-text;
  animation: fadeIn 1s ease-in-out;
  animation-iteration-count: 1;
}

h1,
h2,
body {
  margin: 0;
}

a {
  text-decoration: none;
  color: $blue;
}

p {
  margin: 0;
}

i.material-icons:before {
  display: none;
}
i.material-icons {
  width: 24px;
}

.icon-text {
  display: flex;
  align-items: center;
}

.icon-text i {
  margin-right: 5px;
}

.bookends {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.arrow-up {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid $black-blue;
}

i img {
  max-width: 100%;
  max-height: 100%;
}

.logo {
  background-image: url("/assets/images/logo.png");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  height: 65px;
}

.loading {
  position: absolute;
  padding: 30px;
  left: 0;
  top: 0;
}

.upper {
  text-transform: uppercase;
}

@keyframes scrollBg {
  0% {
    background-position-x: 0%;
  }

  100% {
    background-position-x: 200%;
  }
}

@keyframes scrollBgMobile {
  0% {
    background-position-x: 0%;
  }

  100% {
    background-position-x: 400%;
  }
}

/**
* Widget - shared styles
*
**/

main {
  background-color: $white;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.widget {
  background-color: $white;
  box-shadow: -2px 5px 15px rgba(0, 0, 0, 0.1);
  margin: 15px 0 15px 15px;
  border-radius: 15px;
  position: relative;
  transition: 0.3s ease all;
}

.widget .widget-header {
  padding: 15px 20px;
}

/**
  Icons
*/
.custom-icon {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 0;
  width: 20px;
  line-height: 20px;
  padding-top: 20px;
  margin: 0;
  overflow: hidden;

  &.add_button {
    background-image: url("/assets/images/icons/add_button.svg");
  }
  &.arrow_back {
    background-image: url("/assets/images/icons/arrow_back.svg");
  }
  &.arrow_progress {
    background-image: url("/assets/images/icons/arrow_progress.svg");
  }
  &.arrow_progress_white {
    background-image: url("/assets/images/icons/arrow_progress_white.svg");
  }
  &.arrow_progress_inactive {
    background-image: url("/assets/images/icons/arrow_progress_inactive.svg");
  }
  &.arrow_expand {
    background-image: url("/assets/images/icons/arrow_expand.svg");
  }
  &.arrow_collapse {
    background-image: url("/assets/images/icons/arrow_collapse.svg");
  }
  &.billing_dollar {
    background-image: url("/assets/images/icons/billing_lrg.svg");
  }
  &.btn_external {
    background-image: url("/assets/images/icons/btn_external.svg");
  }
  &.circuit {
    background-image: url("/assets/images/icons/circuit_input.svg");
  }
  &.circuit_critical {
    background-image: url("/assets/images/icons/circuit_critical.svg");
  }
  &.circuit_dark {
    background-image: url("/assets/images/icons/circuit.svg");
  }
  &.comment {
    background-image: url("/assets/images/icons/comment.svg");
  }
  &.comment_read {
    background-image: url("/assets/images/icons/comment_count.svg");
  }
  &.comment_alert {
    background-image: url("/assets/images/icons/comment_alert.svg");
  }
  &.close {
    background-image: url("/assets/images/icons/close.svg");
  }
  &.close_white {
    background-image: url("/assets/images/icons/close_white.svg");
  }
  &.close_sm_white {
    background-image: url("/assets/images/icons/close_sm_white.svg");
  }
  &.customer {
    background-image: url("/assets/images/img_user_nitel@2x.png");
  }
  &.email {
    background-image: url("/assets/images/icons/email.svg");
  }
  &.email_dark {
    background-image: url("/assets/images/icons/email_dark.svg");
  }
  &.health_down {
    background-image: url("/assets/images/icons/health_down.svg");
  }
  &.inline_error {
    background-image: url("/assets/images/icons/inline_error.svg");
  }
  &.inline_error_white {
    background-image: url("/assets/images/icons/inline_error_white.svg");
  }
  &.inline_success {
    background-image: url("/assets/images/icons/inline_success.svg");
  }
  &.inline_success_white {
    background-image: url("/assets/images/icons/inline_success_white.svg");
  }
  &.location {
    background-image: url("/assets/images/icons/location.svg");
  }
  &.location_dark {
    background-image: url("/assets/images/icons/location_dark.svg");
  }
  &.monitoring_lrg {
    background-image: url("/assets/images/icons/monitoring_lrg.svg");
  }
  &.pdf {
    background-image: url("/assets/images/icons/pdf.svg");
  }
  &.phone {
    background-image: url("/assets/images/icons/phone.svg");
  }
  &.phone_dark {
    background-image: url("/assets/images/icons/phone_dark.svg");
  }
  &.help {
    background-image: url("/assets/images/icons/help.svg");
  }
  &.success_lrg {
    background-image: url("/assets/images/icons/success_lrg.svg");
  }
  &.ticket {
    background-image: url("/assets/images/icons/ticket.svg");
  }
  &.ticket_lrg_cir {
    background-image: url("/assets/images/icons/ticket_lrg_cir.svg");
  }
  &.ticket_lrg_cir_white {
    background-image: url("/assets/images/icons/ticket_lrg_cir_white.svg");
  }
  &.order_lrg_cir_white {
    background-image: url("/assets/images/icons/inline_success_white.svg");
  }
  &.ticket_white {
    background-image: url("/assets/images/icons/ticket_white.svg");
  }
  &.services_icon {
    background-image: url("/assets/images/icons/services-icon.svg");
  }
  &.search {
    background-image: url("/assets/images/icons/search.svg");
  }
  &.sort_arrows {
    background-image: url("/assets/images/icons/arrows_sort.svg");
  }
  &.sort_asc {
    background-image: url("/assets/images/icons/arrow_asc.svg");
  }
  &.sort_desc {
    background-image: url("/assets/images/icons/arrow_desc.svg");
  }
  &.csv {
    background-image: url("/assets/images/icons/csv-icon.svg");
  }
  &.filter {
    background-image: url("/assets/images/icons/filter-icon.jpg");
  }
  &.component {
    background-image: url("/assets/images/icons/component-icon.svg");
  }
  &.edit {
    background-image: url("/assets/images/icons/edit-icon.svg");
  }
  &.profile {
    background-image: url("/assets/images/icons/administrator-icon.jpg");
  }
  &.checkmark-white {
    background-image: url("/assets/images/icons/checkmark-icon.svg");
  }
  &.order-icon {
    background-image: url("/assets/images/icons/order-icon.svg");
  }
  &.preparing-white {
    background-image: url("/assets/images/icons/description-white.svg");
  }
  &.preparing-dark {
    background-image: url("/assets/images/icons/description-dark.svg");
  }
  &.survey-white {
    background-image: url("/assets/images/icons/survey-white.svg");
  }
  &.survey-dark {
    background-image: url("/assets/images/icons/survey-dark.svg");
  }
  &.engineering-white {
    background-image: url("/assets/images/icons/engineering-white.svg");
  }
  &.engineering-dark {
    background-image: url("/assets/images/icons/engineering-dark.svg");
  }
  &.mail-white {
    background-image: url("/assets/images/icons/mail-white.svg");
  }
  &.mail-dark {
    background-image: url("/assets/images/icons/mail-dark.svg");
  }
  &.file-upload-icon {
    background-image: url("/assets/images/icons/pdf.svg");
  }
}

@media (max-width: 767px) {
  html,
  body {
    overflow-y: auto;
  }
  main {
    margin-top: 0;
  }
  .mat-app-background {
    background-color: $white;
  }
}
.loading-overlay {
  background-color: rgb(0, 0, 0, 0.7);
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;

  .loading-spinner {
    width: 100px;
    height: 100px;
    border: 4px solid #f3f3f3;
    border-top: 4px solid #3498db;
    border-radius: 50%;
    animation: spin 2s linear infinite;
  }

  &.app-component {
    position: absolute;
  }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


.loading-indicator {
  display: flex;
  justify-content: center;
  align-items: center;
}

app-multiple-service-location .date-picker mat-icon,
app-ticket-tob .date-picker mat-icon {
  width: 25px !important;
}