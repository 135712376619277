.parent-view {
  display: flex;
  height: calc(100vh - 96px); /* 96 is the height of the header + footer & fallback for IE if height below does not work */
  height: calc(var(--vh) - 96px);
  overflow: hidden;
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
  position: relative;
  -webkit-overflow-scrolling: touch;

  & > * {
    flex-basis: 50%;
    min-width: 50%;
  }
}

.parent-container {
  margin-top: 60px;
}

@media (max-width: 767px) {
  .parent-view {
    height: auto;
  }
  .parent-view > * {
    flex-basis: unset;
    width: 100vw;
  }

  .parent-container {
    margin-top: 75px;
  }
}