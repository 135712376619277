/**
* Toggle
*
**/

.toggle-wrapper {
  cursor: pointer;
  display: block;
  font-size: 22px;
  margin-bottom: 12px;
  margin-left: 15px;
  position: relative;
  padding-top: 3px;
  padding-left: 34px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  input {
    cursor: pointer;
    opacity: 0;
    position: absolute;
    height: 0;
    width: 0;

    &:checked ~ .checkmark {
      background-color: $white;
      transform: scale(1.3);
    }
    &:checked ~ .checkmark:after {
      display: block;
    }
  }
}

.checkmark {
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 0;
  height: 17px;
  width: 17px;
  border: 1px solid $white;
  margin: 3px 4px;
  transition: 0.3s ease all;

  &:after {
    display: none;
    content: '';
    position: absolute;
    border: solid $black;
    border-width: 0 2px 2px 0;
    left: 6px;
    top: 2px;
    width: 4px;
    height: 9px;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}
