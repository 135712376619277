@import "../styles/colors.scss";

// --- --- --- --- --- --- ---
// Material styles for FORM FIELD elements
// --- --- --- --- --- --- ---
.mat-form-field-appearance {
  .mat-form-field-label {
    color: $cobalt;
    font-family: "open-sans";
    font-size: 18px;
    font-weight: normal;
    padding: 13px 0 15px 25px;
    white-space: normal;
    width: calc(100% - 30px);
  }
  .mat-form-field-wrapper {
    padding-bottom: 0;
  }
  .mat-form-field-infix {
    border: 0;
    padding-bottom: 0;
  }
  .mat-form-field-underline {
    display: none;
  }
}
.mat-form-field {
  &.mat-form-field-invalid .mat-form-field-label {
    color: $cobalt;
  }
}
.custom-invalid {
  .mat-select {
    border: 2px solid $darkorange;
  }
}

.mdc-notched-outline[ng-reflect-open="true"] .mdc-notched-outline__notch {
  display: none !important;
}

.mat-mdc-form-field {
  min-width: 100% !important;
  margin-top: 3px;
}

.container-selects,
.radio-container,
.billing-block {
  .mat-mdc-form-field {
    min-width: unset !important;
  }
}
.address-merged .mat-mdc-form-field {
  min-width: 100px !important;
}

.ticket-count-dropdown .mat-mdc-form-field {
  min-width: 350px !important;
}

app-billing-page,
app-create-ticket,
orders-shell,
app-inventory-page,
app-tickets-shell,
app-unm-page app-analytics-time-selector,
app-unm-data-table table,
app-cellular-table table,
metric-table-component .container-title,
app-table-page .container-top,
app-table-orders table,
app-table-orders .search-bar {
  .mat-mdc-text-field-wrapper {
    border-radius: 30px;
    background-color: $white !important;
    border: 1px solid $gray !important;
    --mdc-filled-text-field-active-indicator-height: 0px !important;
    --mdc-filled-text-field-focus-active-indicator-height: 0px !important;
    --mdc-filled-text-field-container-shape: 0px !important;

    .mat-mdc-form-field-input-control {
      padding-left: 25px;
    }
  }
}

.mat-mdc-form-field-subscript-wrapper {
  display: none;
}

.mat-mdc-form-field-focus-overlay {
  background-color: $input-gray !important;
}
.mat-mdc-option,
.mat-mdc-select {
  color: $primary-text !important;
}

.mat-form-field-appearance.mat-form-field-can-float {
  .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label,
  .mat-form-field-autofill-control:-webkit-autofill
    + .mat-form-field-label-wrapper
    .mat-form-field-label,
  &.mat-form-field-should-float .mat-form-field-label {
    display: none;
  }
}

.mat-option {
  padding-right: 0 !important;

  &.mat-active,
  &.mat-selected,
  &:hover:not(.mat-option-disabled) {
    background-color: $light-blue !important;
    color: black !important;
  }

  &.mat-option-disabled {
    opacity: 0.7;
  }
}
.mat-option-text {
  border-bottom: 2px solid $input-gray;
  color: $black-blue;
  font-size: 14px;
  font-weight: 400;
}
.mat-option:last-of-type {
  .mat-option-text {
    border-bottom: none;
  }
}

// --- --- --- --- --- --- --- --- ---
// Material styles for RADIO buttons
// --- --- --- --- --- --- --- --- ---
.mdc-radio .mdc-radio__inner-circle {
  background-color: $cobalt !important;
}

.mdc-radio .mdc-radio__ripple .mdc-ripple-surface {
  background-color: $blue !important;
}

.mdc-radio--checked .mdc-radio__outer-circle {
  border-color: $cobalt !important;
}
.mdc-radio__label-content,
.mdc-select__menu .mdc-list-group__subheader,
.mdc-select__menu .mdc-list-item {
  font-family: "open-sans";
  font-weight: 100;
}

.mdc-radio__label-content {
  font-size: 14px;
  font-weight: 400;
  padding: 10px !important;
}

.mdc-radio--checked .mdc-radio__label-content {
  font-weight: 700;
  color: $cobalt;
}

// --- --- --- --- --- --- ---
// Material styles for DIALOGs
// --- --- --- --- --- --- ---
.mat-dialog-container {
  color: $black-blue;
  padding: 0 !important;
  border-radius: 15px !important;
}
.cdk-overlay-dark-backdrop {
  background: rgba(15, 39, 68, 0.8);
}
.cdk-overlay-pane {
  transition: 0.3s ease height;
}

@media all {
  /** IE 11 fixes go here */
  .mat-select-panel {
    min-width: calc(100% + 32px) !important;
  }
  .cdk-overlay-pane {
    display: block;
  }
}

// --- --- --- --- --- --- ---
// Material styles for Input elements
// --- --- --- --- --- --- ---
input.mat-input-element {
  border: 1px solid $input-gray;
  border-radius: 25px;
  color: $cobalt;
  font-family: "open-sans";
  font-weight: 100;
  height: 20px;
  padding: 15px 25px;
  width: calc(100% - 55px);
}

.mat-form-field-flex {
  align-items: center !important;
}
.custom-textarea {
  .mat-form-field-wrapper
    .mat-form-field-flex
    .mat-form-field-infix
    .mat-form-field-label-wrapper
    label {
    width: calc(100% - 50px);
    word-break: break-word;
    padding: 25px;
  }
}

// --- --- --- --- --- --- --- --- --- --- --- --- ---
// Material styles for Progress and Spinner elements
// --- --- --- --- --- --- --- --- --- --- --- --- ---
.mat-progress-spinner {
  margin: 10px auto;

  circle,
  .mat-spinner circle {
    stroke: $orange;
  }
}

.widget.parent-list {
  .mat-select {
    height: auto;
    border: 0;
  }
  .mat-select-value {
    padding: 0;
  }
  .mat-select-arrow-wrapper {
    padding: 5px 0 0;
  }
  .mat-select-value-text {
    color: $blue;
    font-weight: 700;
    font-size: 14px;
    text-transform: uppercase;
    display: flex;
    justify-content: flex-end;
    margin-right: 10px;
  }
  .mat-form-field-appearance .mat-form-field-infix {
    padding: 0;
    width: 170px;
  }
}

.billing-option .mat-option-text {
  text-transform: uppercase;
}

// --- --- --- --- --- --- --- --- --- --- --- --- ---
// Material styles for Comment Textare
// --- --- --- --- --- --- --- --- --- --- --- --- ---
.comment-form {
  display: flex;
  width: 100%;
  padding: 8px 3px;

  textarea.mat-input-element {
    background: none;
    border: none;
    font-family: "open-sans", sans-serif;
    font-size: 14px;
    color: $white;
    outline: none;
    resize: none;
    overflow: hidden;
    padding: 4px 0;
    margin: 0;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    min-height: 17px !important;
  }

  .mat-form-field-label-wrapper {
    top: 3px;
    padding-top: 0;
  }
  .mat-form-field-appearance .mat-form-field-infix {
    padding: 0 0 0 0;
  }
  .mat-form-field-appearance .mat-form-field-label {
    top: 0;
    padding: 0;
  }
  .mat-form-field-appearance .mat-form-field-label {
    font-family: "open-sans", sans-serif;
    font-size: 14px;
    font-weight: normal;
    color: $white;
    opacity: 0.7;
    transition: 0.3s ease opacity;
  }
  .mat-form-field-appearance:hover .mat-form-field-label,
  .mat-form-field.mat-focused .mat-form-field-label {
    color: $white;
    opacity: 1;
    transition: 0.3s ease opacity;
  }
  .mat-form-field-appearance .mat-form-field-subscript-wrapper {
    display: none;
  }
}
.comment-form > * {
  width: 100%;
}

metric-table-component {
  .mat-sort-header .mat-sort-header-content {
    text-transform: uppercase;
    font-family: "open-sans", Helvetica, sans-serif;
    font-weight: 600;
  }
  .mat-mdc-row,
  .mdc-data-table__content {
    font-family: "open-sans", Helvetica, sans-serif;
    font-size: 14px;
    font-weight: normal;
  }
  .mat-select-value-text {
    font-size: 14px;
  }
  .mat-select-value {
    padding: 0px 10px;
    color: #000;
    font-size: 12px;
  }
  .mat-select {
    height: 36px;
  }
  .table-filter-select {
    border-radius: 10px;
    width: 100%;
  }
  .mat-select-trigger {
    margin-top: 0;
  }
  ngx-mat-timepicker-toggle .mat-mdc-icon-button.mat-mdc-button-base {
    margin-top: -4px;
    padding: 0;
  }
  .mat-datepicker-toggle {
    margin-top: -3px;
  }
}

.dimension-filter-box {
  .mat-button-toggle-group {
    margin: 0 !important;
  }
  .mat-button-toggle-checked {
    background-color: $cobalt;
    color: $white;
  }
  .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
    padding: 7px 12px;
    line-height: normal;
    font-size: 14px;
    font-weight: 600;
    font-family: "open-sans", Helvetica, sans-serif;
  }
}

app-cellular-table table,
app-unm-data-table table {
  .mat-mdc-select {
    border: 1px solid $input-gray;
    border-radius: 10px;
    font-size: 13px;
    font-weight: 400;
    color: #666 !important;
    font-family: "open-sans";
    width: 88%;
    padding: 3px 5px;
  }
  .mat-mdc-option {
    padding-left: 7px;
    padding-right: 7px;
    min-height: auto;
    font-weight: 200 !important;
    color: #666 !important;
    font-size: 13px !important;
  }

  .mat-mdc-icon-button {
    padding: 0 !important;
  }

  .mat-mdc-paginator-page-size-select {
    margin: 0 !important;
    width: auto !important;
    border: 0px !important;
    min-width: 50px !important;
  }
  .mat-mdc-paginator .mat-mdc-form-field-infix {
    min-width: 50px !important;
  }

  mat-paginator .mat-mdc-text-field-wrapper {
    border: 0px !important;
  }
}

app-cellular-table,
app-unm-data-table,
metric-table-component,
app-table-orders {
  .mat-mdc-paginator-page-size {
    display: inline !important;
  }
  .mat-mdc-paginator {
    margin: 30px 0 15px 0;
  }
}
