@font-face {
  font-family: 'open-sans';
  src: url('../assets/fonts/OpenSans-Light.woff2') format('woff2'),
    url('../assets/fonts/OpenSans-Light.ttf') format('ttf');
  font-weight: 300;
}

@font-face {
  font-family: 'open-sans';
  src: url('../assets/fonts/OpenSans-Regular.woff2') format('woff2'),
    url('../assets/fonts/OpenSans-Regular.ttf') format('ttf');
  font-weight: 400;
}

@font-face {
  font-family: 'open-sans';
  src: url('../assets/fonts/OpenSans-SemiBold.woff2') format('woff2'),
    url('../assets/fonts/OpenSans-SemiBold.ttf') format('ttf');
  font-weight: 600;
}

@font-face {
  font-family: 'open-sans';
  src: url('../assets/fonts/OpenSans-Bold.woff2') format('woff2'),
    url('../assets/fonts/OpenSans-Bold.ttf') format('ttf');
  font-weight: 700;
}

@font-face {
  font-family: 'open-sans';
  src: url('../assets/fonts/OpenSans-SemiBold.woff2') format('woff2'),
    url('../assets/fonts/OpenSans-SemiBold.ttf') format('ttf');
  font-weight: 800;
}
