$yellow: #ffbb2c;
$orange: #ff742e;
$darkorange: #ef4732;

$green: #3dcc64;

$light-blue: #e7f9fc;
$blue: #18c1df;
$dark-blue: #152c4f;
$navy: #102744;
$cobalt: #2a4b98;
$cobalt-dark: #24438b;
$cobalt-hover: #365fbf;
$summer-sky: #3e95dc;

$purple: #81548f;
$light-purple: #7a4b8a;
$purple-blue: #17328d;

$white: #ffffff;

$black: #000000;
$black-blue: #0f2744;
$primary-text: #0f2744;

$lightgray: #f6f6f8;
$gray: #cccccc;
$darkgray: #8793a2;
$select-gray: #edeff1;
$input-gray: #d0d1d5;
$header-gray: #e1e2e6;
$badge-gray: rgba(255, 255, 255, 0.2);